import stores from "@/Stores/stores.js";
import {storeToRefs} from "pinia";
import {loadLanguageAsync} from "laravel-vue-i18n";
import {Axios} from "@/Config/Axios.js";


/**
 * Asynchronously set the application locale.
 * @param {string} locale - The locale to be set.
 * @param {boolean} [triggeredByClick=true] - Flag indicating whether the setting was triggered by a user click.
 */
async function setLocale(locale, triggeredByClick = true) {
    const {configStore} = stores();
    const {activeLocale, activeObject} = storeToRefs(configStore);

    if (!triggeredByClick) {
        let tempLocale = activeLocale.value || '';
        await changeLocale(tempLocale);
        return;
    }

    // Proceed only if locale is actually changed.
    if (activeLocale.value !== locale || activeObject.value === '') {
        await changeLocale(locale);
    }
}

/**
 * Asynchronously change the application locale.
 * @param {string} locale - The locale to be set.
 */
async function changeLocale(locale) {
    const {objectStore, questionStore, configStore} = stores();

    try {
        // Send a post request to set the locale.
        const res = await Axios.post(route('set-locale'), {locale_id: locale});
        // Update the store data with the response.
        await configStore.setActiveLocale(res.data.active_locale);
        await objectStore.setObjectData(res.data.object);
        await objectStore.setAttributions(res.data.attributions);

        console.log('Log res.data',  res.data)

        await questionStore.setSuggestedQuestions(res.data.suggested_questions);

        // Set the new locale in the i18n module. This is for the ui translations.
        await loadLanguageAsync(res.data.active_locale);
    } catch (error) {
        // Log an error if the locale fetch fails.
        console.error('Error fetching locale:', error);
    }
}

export default setLocale;
