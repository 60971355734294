import stores from "@/Stores/stores.js";
import {storeToRefs} from "pinia";
import {Axios} from "@/Config/Axios.js";
import {router} from "@inertiajs/vue3";
import setLocale from "@/Services/setLocale.js";

async function accessCheck(callBack) {
    const {accessStore, configStore} = stores();
    const {passwordEnteredByUser,} = storeToRefs(accessStore);

    await Axios.post(route('access.password.check'), {
        password: passwordEnteredByUser.value,
    }).then(async function (response) {
        await configStore.setActiveObject(response.data.active_object);
        await configStore.setActiveClient(response.data.active_client);
        await configStore.setLanguages(response.data.languages);
        await configStore.setStoreVersionCurrent(response.data.store_version);
        await configStore.setActiveComponents(response.data.active_components);
        await configStore.setCarousel(response.data.carousel);


        //SET LOCALE
         await setLocale('', false);

        //STORE VERSION CHECK
        const {storeVersion, storeVersionCurrent} = storeToRefs(configStore);
        const storageVersionHasChanged = storeVersion.value !== storeVersionCurrent.value;


        if (storageVersionHasChanged) {
            localStorage.clear(); // Clears the entire local storage
            passwordEnteredByUser.value = '';
            storeVersion.value = storeVersionCurrent.value;
            router.get(route('landing.index'));
        }

        callBack(response)
    });
}


export default accessCheck;



function areKeysPresentInLocalStorage(keys) {
    return keys.every(key => localStorage.getItem(key) !== null);
}
